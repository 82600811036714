import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ErrorsModule } from './core/errors/errors.module';
import { NgModule } from '@angular/core';
import { EssAngularComponentsModule } from '@ess-front/angular-components';
import { environment } from '@environment';
import { NotificationModule } from '@ess-front/brain-shared';
import { AuthModule } from '@ess-front/authorization';
import { TranslationService } from '@ess-front/shared';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    NotificationModule,
    AuthModule,
    AppRoutingModule,
    ErrorsModule,
    EssAngularComponentsModule.forRoot(environment),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: TranslationService,
      useValue: { getActiveLanguage: () => 'en' },
    },
  ],
})
export class AppModule {}
